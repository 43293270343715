@import 'variables';

.hide-proposal-pop{

  .pop-soumission{
    display: none !important;
  }


}


.w-container{
  opacity: 1;
  height: 900px;
  visibility: visible;

  transition: opacity 0.5s, height 1s, visibility 1s;
  
  &.hide{
    transition-delay:  0.2s;
    visibility: hidden;
  }


  &.disapear{
    opacity: 0;
    
    
  }

  &.minimize{
    height: 0;
    //transition-delay:  1s;
  }

 
}

.vs {
  width: 100%;

  .vs-buttons {
    
    text-align: right;
    margin-right: 20%;
    margin-top: 2em;
    cursor: pointer;
    font-size: 1.1em;


    

    a {
      padding: 1em;
      margin-right: 1.2em;
    }
  }

  .w-services {
    display: flex;
    
    margin: 0 auto;
    width: 90%;
    justify-content: space-evenly;


    .services-list{
      width: 90%;
    }


    h3 {
      text-align: center;
    }

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      border: 5px dotted black;
      padding: 0.5em;
      min-height: 400px;
      // background-color: white;
      box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;

      .service {
        background-color: #a50000;
        border-radius: 10px;
        margin: 1em;

        display: flex;
        //width: 150px;
        max-width: 150px;
        width: 40%;
        height: 80px;

        position: relative;
        cursor: pointer;

        i{
          transition: font-size 0.2s;
        }

        &:hover{
          opacity: 0.8;

          i{

                           
          font-size: 1.5em;
          opacity: 0.8;

          }
        }





        svg{
            margin: 0.6em;
            height: 70% !important;
            


        }

        span{
            display: flex;
            font-size: 0.8em;
            color: white;
            height: 100%;
            padding: 0.5em;
            align-items: center;
            text-align: center;
            

            

            &.less{
              cursor: pointer;

              height: 100%;

              color: white;
              position: absolute;
              right: -15px;
              top: -18px;
              width: 40px;
              height: 40px;
              padding: 0.5em;
              border: 2px solid white;

              font-size: 1em;    
              background-color: #a50000;
              justify-content:center; 
              border-radius: 0.3em;
              transform: rotate(1deg);

         
              

             

            }
            
            &.add{
              cursor: pointer;
                height: 100%;
                transform: rotate(2deg);
                color: white;
                position: absolute;
                right: -15px;
                top: -18px;
                width: 40px;
                height: 40px;
                padding: 0.5em;
                border: 2px solid white;
                border-radius: 0.3em;
  
                font-size: 1em;    
                background-color: green;
                justify-content:center;  


              

              

                .success{
                    color: white;
                    

                    display: block;
                    padding: 0;
                    margin:0;
                    
                }


                
            }


        }

       
      }
    }



    .services-selected{
        width: 40%;
    }
  }
}

.vs-menu-proposal {
  margin: 0 0 3em 0;
  height: 60px;

  .arrow {
    text {
      font-size: 2em;
      z-index: 2;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    background-color: #f5f5f5;

    border-radius: 2px;
    width: 100%;
    justify-content: center;

    z-index: 2;

    li {
      position: relative;

      width: 10%;
      min-width: 150px;
      font-size: 0.9em;

      list-style: none;
      margin: 0.2em 0 0.2em 0;
      padding-left: 0.5em;
      
      cursor: pointer;
      height: 60px;



      .index {
        font-weight: bold;
        font-size: 1.5em;
      }

      .arrow {
        
        
        transition: 0.5s fill, 0.5s width;
        filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.171));

        &.completed {
          fill: black;
          width: 100%;
        }

        &.current {
          fill: #a50000;

          width: 85%;
        }
      }

      position: relative;

      display: flex;
      align-items: center;

      .title {
        color: white;
        position: absolute;
        left: 20%;
        padding: 1em;
        display: block;
        z-index: 9999;
      }
    }

    &.fixed {
      top: 105px;
    }
  }
}


@media all and (orientation:landscape) {

  .vs{
    .ws-services{
      ul{
        min-height: 200px;
      }
    }
  }
  .w-container{
    
    
  }
}


.vs-windows {

  h2{
    font-size: 1.5em;

  }

 


  h2 {
    text-align: center;
    color: black;
    margin-bottom: 1em;


    .underbar {
      display: block;
      width: 5%;
      background-color: #a50000;
      height: 3px;
      margin: auto;
      margin-top: 0.3em;
    }
  }

  form {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;

    div {
      padding: 2em;
    }
  }
}



@include media-breakpoint-down(smm){


  .forfait-container{
    margin: 1em;
  }

  .vs-windows{

    .w-container{
      margin: 0;
    }
  }

  .vs-menu-proposal {

    ul{
      &.fixed{
        top: 60px;
      }
    }

  }


}



@include media-breakpoint-down(lg){

  .vs-windows{
    form{
      div{
        padding: 1em
      }
    }
   
  }

  .vs{

    .w-services{
      width: 100%;
      margin: 0;
      .services-list{
        width: 100%;
      }

      ul{
        .service{
          height: 40px;
          margin: 0.5em;
          text-align: center;

          

          svg{
            display: none;
          }

          span{
            justify-content: center;
            width: 100%;

            &.add{

              height: 30px;
              width: 30px;

              .icofont-tick-mark{
                font-size: 0.8em;
              }
              
            }
          }
        }
      }
    }
  }


  
  .vs-menu-proposal {

    ul{

      

      li{
        max-width: 300px;
        width: 40%;

        .title{
          width: 50%;
        }


        &:not(.current){
          display: none;
        }


        &.last{
          display: flex ;
        }


        &.current + li{
          display: flex ;
        }

    

      }

  
    }

  }
  
  }



@include media-breakpoint-down(sm){




  .caroussel{
    width: 100%;
  }
  .vs-windows{

    form{
      div{
        padding: 0;
      }
    }

    h2{
      margin-bottom: 0.5em;
      .underbar {
        width: 15%;
      }
    }


  }

  





  .vs-menu-proposal {

    ul{

      li{



        width: 45%;

        &.current + li{
          display: none ;
        }

      }

    }



  }

}