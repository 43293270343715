@use 'services'; //Page services
@use 'modal'; //Page services
@use 'proposal'; //Page soumission@use 'variables';
@import 'variables';

body {
    background-color: #f5f5f5 !important;
}

a {
    color: white !important;
}

a.icon {
    color: black !important;
}

a:hover {
    opacity: 0.5;
}

a.mail-to {
    color: black !important;
}

a.mail-to:hover {
    cursor: pointer;
    opacity: 0.5 !important;
    color: #a50000;
}

footer a.mail-to {
    color: white !important;
    cursor: pointer;
}

a {
    text-decoration: none !important;
}

.brand-logo a:hover {
    opacity: 1 !important;
}

.pop-soumission {
    background-color: #00a5a5;
    font-size: 1em;
}

.pop-soumission:hover i {
    color: #a50000 !important;
    transition: color 0.4s;
}

.pop-soumission:hover {
    background-color: black !important;
    font-size: 1.7em !important;
    opacity: 1 !important;
    transition: background-color 0.5s;
}

.pop-soumission .magnifyer {
    position: absolute;
    top: 0;
    left: 49.5vw;
    padding: 5px;
    font-size: 1.5em;
    margin: 5px;
    background-color: white;
    color: #00a5a5;
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    text-align: center;
    border: 5px solid;
}

.pop-soumission:hover .magnifyer {
    color: black !important;
    transition: color 0.5s;
    opacity: 1 !important;
}

.card {
    border: unset !important;
    flex-direction: row !important;
    z-index: -999 !important;
}

.card-cute {
    cursor: pointer;
}

.card-cute p {
    color: whitesmoke;
    font-size: 22px;
    transition: font-size 0.2s;
}

.card-cute.card-cute-active {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-cute.card-cute-active p {
    font-size: 24px;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px !important;
    height: 100%;
}

.hamburger:hover {
    opacity: 0.7;
}


/*
Bootstrap class override 
*/

.navbar {
    background-color: #a50000;
    color: whitesmoke;
    width: 100%;
    box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
    display: flex;
    justify-content: space-between;
    padding: unset;
    padding-left: 5%;
    padding-right: 5%;
    position: fixed !important;
    z-index: 999;
    
 
}



.navbar ul,
.navbar li {
    list-style-type: none;
    margin: 0;
}

.navbar a {
    color: whitesmoke !important;
    font-weight: bold;
}

.navbar a:hover {
    opacity: 0.8;
    text-decoration: none;
}

.link {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 600px;
    transition: max-height 3s;
}

#root>div>div>nav>div>button {
    background-color: whitesmoke;
    border: 1px solid black;
}

.navbar-collapse {
    flex-grow: unset;
}

.btn-plus {
    background-color: #a50000;
    border: unset;
    opacity: 1 !important;
    
}

.btn-plus:hover {
    transition: background-color 0.3s;
    background-color: black;
}

h2 {
    font-size: 4vh;
    font-weight: bold !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    border-bottom: 2px solid #a61c26 !important;
}


/*
  media
*/

@media screen and (max-width: 1400px) {
    .pop-soumission {
        position: fixed;
        bottom: 0;
        border-radius: 5px;
        border: 2px solid block;
    }
    .pop-soumission .magnifyer {
        top: -66px !important;
        bottom: unset !important;
        left: 90%;
    }
    .brand-logo {
        width: 100% !important;
    }
    .navbar {
        justify-content: center !important;
    }
    .link {
        display: flex;
        flex-wrap: wrap;
        min-width: 1000px;
        justify-content: center !important;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s;
    }
    .navbar-active .link {
        max-height: 200px;
    }
    .link li {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
    section#real-estate-management {
        display: block !important;
    }
    section#real-estate-management>div {
        display: block !important;
        max-width: unset !important;
        min-width: unset !important;
        text-align: center;
    }
    section#real-estate-management p {
        width: unset !important;
    }
    section#my-aparts-inc div {
        flex-direction: column;
    }
    .card {
        flex-direction: column !important;
        background-color: unset !important;
        position: unset !important;
    }
    .card p {
        position: relative !important;
    }
    .card-text {
        order: 1 !important;
        margin-top: 30px;
        margin-right: 10px;
    }
    .card-text p {
        width: unset;
    }
    .card-image {
        order: 0 !important;
    }

    .checkbox-group{
        padding-left: 1vw;
    }
}

@media screen and (max-width: 950px) {
    .btn-plus {
        align-self: center !important;
    }
    .banner-image,
    .magnifyer {
        display: none !important;
    }
    .home-banner {
        margin: 0 !important;
    }
    .description {
        max-width: 100% !important;
        text-align: center !important;
    }
    .facecard-mathieu {
        float: unset !important;
    }
    #about {
        width: 100% !important;
    }
    .face-card {
        width: 100% !important;
        margin: 0 auto !important;
    }
    .face-card div {
        width: 100% !important;
        margin: 0 auto !important;
    }
    .title h2 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: center;
    }
    #my-aparts-inc #description {
        margin: 20px auto 20px auto !important;
    }
    #my-aparts-inc h2 {
        width: 100% !important;
        text-align: center;
    }
    #my-aparts-inc button {
        align-self: center;
    }
    .card-avantages {
        margin: 0 !important;
        width: 100% !important;
        margin-bottom: 100px !important;
        text-align: center !important;
    }
    .card-avantages>div {
        left: 0 !important;
    }
    .card-avantages div {
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .card-avantages div p {
        text-align: center !important;
    }

    h5{
        width: 100%;
        text-align: center;
    }

    .checkbox-group{
        padding-left: 8vw;
    }
 

}

@media screen and (max-width: 500px) {
    .bouncy-img {
        width: 100%;
    }

    .checkbox-group{
        padding-left: 10vw;
    }


  
}

.red{
    color: #a50000;
}


.section-red{
    background-color: #a50000;
    color:  white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 2em;
    border-radius: 5px;
    margin-bottom: 30px;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.success{
    color: rgba(22, 179, 30, 0.76);

}x

h2{
    text-align: center;
    margin-bottom: 3em;
}

.hidden{
    display: none;
}

.emphasis{
    margin-bottom: 2em;
    color: #a50000;
    text-shadow: 0px 20px 40px rgba(1,1,1,0.4); 
    text-align: center;
}

.transparent{
    opacity: 0.7;
}


.fixed{
    position: fixed;


}

.btn-submit{
    width: 50%;
    margin: auto;
    display: block;
    line-height: 2em;
    border-radius: 1em;

    font-size: 1.2em;
}



