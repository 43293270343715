@import 'variables';

.caroussel{
    margin-bottom: 3em;
    margin: auto;

    width: 90%;

    
    

    ul{
        margin: 2em 0;
    }
}

.btn-carousel{
    color: white;
    background-color: #a50000 ;
    padding: 0;
    width: 50px;
    padding: 0.3em 0.1em;
    height: 55px;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    top: 50%;


    transform: translateY(-50%);
    font-size: 1.5em;
    
    cursor: pointer;

    &:hover{
        opacity: 0.3;
    }
}

.btn-prev{

    left: -30px;
}

.btn-next{

    right: -30px;
}




.forfait-container{
    border-radius: 10px;
    border:  2px solid #a5000034;
    margin: 0 2em;  
    
    button{
        &:hover{
            opacity: 0.8;
        }
    }

   

    


    &.prefered{
        
        h3{

            background-color: #a50000;
            color: white;

        }
    }

    h3{
        background-color: #a5000034;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    
        padding: 0.5em 0;
        font-size: 1.2em;
        text-align: center;
        
    }

    button{
        background-color: #a50000;
        border: unset;
        padding: 1em;
        color: white;
        border-radius: 10px;
        text-align: center;
        margin: auto;
        margin-bottom: 1em;
        width: 90%;
        display: block;
        
    }

}




.nom-service i{
    padding: 0.75em 0.2em 0.75em 0;
}

.transluscide{
    opacity: 0.4;
}



.slick-track {
    display: flex !important;
    
  }
  
  .slick-slide {
    height: auto;
    width: 20%;
  }


section.services{

    margin-bottom: 1em;

    .row-services{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .service{

        transition: background-color 0.1s, box-shadow 0.2s;
        background-color: #a50000;
        box-shadow: unset;
        width: 20%;
        width: 200px;
        height: 200px;
        margin: 1em;
        padding: 1em;
        cursor: pointer;

        &:hover{

            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

            .subtitle{

                color: #a50000;;
                
            }

        }
        
    }

    .service-picto{

        background-color: #a50000;
        width: 20%;
        width: 200px;
        height: 200px;
        margin: 1em;
        padding: 1em;
        
    }

    h3{
   
        
        font-size: 1.1em;

        .subtitle{
   
            color: white;
            text-shadow: "0px 20px 40px rgba(0,0,0,0.4)";
             
         }
         
     }

}

.services-infos{
    margin: 2em 10%;
    font-size: 1.2em;
    text-align: justify; 
    
}

#s-plans-offerts{
    width: 90vw;
}


@include media-breakpoint-down(sm){

    section{

        &.services{

            h3{
                font-size: 0.8em;
            }


            .service{

                width: 130px;
                height: 130px;

            }
        }

    }



    .btn-prev{

        left: 10px;
    }
    
    .btn-next{
    
        right: 10px;
    }



.forfait-container{
    margin: 0 5em;  
}
    

}

@include media-breakpoint-down(smm){

    .btn-prev{

        left: 10px;
    }
    
    .btn-next{
    
        right: 10px;
    }

    .btn-carousel{
        height: 30px;
        width: 30px;
        font-size: 0.9em;
         
    }



.forfait-container{
    margin: 0 2em;  
}
    

}