$breakpoints: (
  xs: 0,
  smm:500px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


@mixin media-breakpoint-down($breakpoint) {

  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @media (max-width: $breakpoint-value) {
    @content;
  }

 }




