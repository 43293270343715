@import 'variables';
 
.custom-modal{

        display: none; 
        position: fixed; 
        z-index: 1; 
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        background-color: rgba(126, 126, 126, 0.452);

        .custom-modal-close{
            
        

            
            font-size: 2em;
            padding: 0.2em 0.5em;
            font-weight: bold;
            top: 0;
            right: 0;
            position: absolute;
            cursor: pointer;

            &:hover{
                opacity: 0.5;
            }
        }

        .custom-content{
            
            -webkit-transition: all 5s linear 0s;
            transition: all 5s linear 0s;
            position: relative;
            background-color: white;
            min-width: 300px;
            max-height: 700px;
            margin-left: 25%;
            margin-right: 25%;
            margin-top: 10%;
            border-radius: 5px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: 2em;
            display: flex;
            overflow: auto; 



            .picto{
                margin: 2em;
                //background-color: #a50000;
            }



            ul{
                list-style-type: none;

                li{
                    margin: 1em 0;
                }
            }

      

            .subtitle{
                color: #a50000;
            }
        }

        &.enable{
            display: block;

            
        }
}





@include media-breakpoint-down(sm){

    .custom-modal{

        .custom-content{
            
            -webkit-transition: all 5s linear 0s;
            transition: all 5s linear 0s;
            position: relative;
            background-color: white;
            min-width: 300px;

            margin-left: 1em;
            margin-right: 1em;
            margin-top: 30%;
            border-radius: 5px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: 2em;
            display: flex;
            overflow: auto; 
    
    
    
        }
    }


}


